import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EMPLOYEMENT_STATUS,
  EMPLOYED_FOR,
  renderSection,
  formatIssueText,
} from "../utils/Constant";
import { filterArrayByIssueType } from "../shared/Helpers";
import getIssues from "../app/services/getIssues";
import EditIcon from "../assets/img/Edit.svg";
import UpdateAtjData from "../components/UpdateATJData";
import getUser from "../app/services/getUser";
import { getToken } from "./../shared/Helpers";
import getParagraphs from "../app/services/getParagraphs";
import generateCaseDetailLetter from "../app/services/generateCaseDetailLetter";
import Cycle from "../assets/img/cycle.svg";
import config from "../config";

function capitalizeFLetter(text) {
  return text[0].toUpperCase() + text.slice(1);
}

function PreviewLetter() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [statements, setStatements] = useState([]);
  const [formType, setFormType] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [issues, setIssues] = useState({
    other: [],
    employment: [],
    dismissal: [],
    redundancy: [],
  });
  const token = getToken();
  const { lastPage, dataInputs } = state || {};
  const [surveyData, setSurveyData] = useState(dataInputs || {});
  const [questionAnswers, setQuestionAnswer] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  const encryptEmail = (email) => {
    let result = "";
    for (let i = 0; i < email.length; i++) {
      let charCode = email.charCodeAt(i);
      // Simple encryption by shifting char code
      let encryptedCharCode = charCode + 3; // You can choose any number to shift
      result += String.fromCharCode(encryptedCharCode);
    }
    return result;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await getUser(token);
        const updatedSurveyData = data.survey;

        if (updatedSurveyData.acas_notified === "no") {
          updatedSurveyData.date2 = "";
          updatedSurveyData.deadline2 = "";
          updatedSurveyData.date3 = "";
        }

        if (updatedSurveyData.received_acas === "no") {
          updatedSurveyData.date3 = "";
        }
        const questions = JSON.parse(updatedSurveyData.generatedQuestions);
        const additionalQuestions = questions
          .filter((q) => q.title && updatedSurveyData[q.name])
          .map(
            (q) =>
              `<p><b>${q.title}</b></p>\n\n<p>${
                updatedSurveyData[q.name] || "-"
              }</p>`
          )
          .join("\n");

        const manualQuestion = `<p><b>If there is anything else you need us to know, please write it here:</b></p>\n\n${
          updatedSurveyData.extra_question &&
          updatedSurveyData.extra_question.trim()
            ? updatedSurveyData.extra_question
            : "-"
        }`;

        const allQuestions = `${additionalQuestions}\n${manualQuestion}`;

        setQuestionAnswer(allQuestions);
        setSurveyData(updatedSurveyData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (!dataInputs && token) {
      fetchUserData();
    } else if (dataInputs) {
      fetchUserData();
      setSurveyData((prevData) => {
        const updatedData = { ...prevData, ...dataInputs };
        if (updatedData.acas_notified === "no") {
          updatedData.date2 = "";
          updatedData.deadline2 = "";
          updatedData.date3 = "";
        }

        if (updatedData.received_acas === "no") {
          updatedData.date3 = "";
        }

        return updatedData;
      });
    } else {
      navigate("/login");
    }
  }, [dataInputs, token, navigate]);

  useEffect(() => {
    const fetchIssues = async () => {
      try {
        const response = await getIssues();
        const data = response.data.data;
        const issueTypes = [1, 2, 3, 4];

        const newIssues = issueTypes.reduce((acc, type) => {
          acc[type] = filterArrayByIssueType(data, type).map((issue) => ({
            value: issue.attributes.TopicId,
            text: issue.attributes.Name,
          }));
          return acc;
        }, {});

        const filterQuery =
          surveyData.statements_list && surveyData.statements_list.length > 0
            ? surveyData.statements_list
                .map((id) => `&filters[id][$in]=${encodeURIComponent(id)}`)
                .join("")
            : "&filters[id][$in]=";

        const { data: { data: paragraphsData } = {} } = await getParagraphs(
          filterQuery
        );

        setStatements(paragraphsData);
        setIssues({
          employment: newIssues[1],
          other: [{ value: "none", text: "None" }, ...newIssues[2]],
          dismissal: newIssues[3],
          redundancy: newIssues[4],
        });
      } catch (error) {
        console.error("Error fetching issues:", error);
      }
    };

    fetchIssues();
  }, [surveyData.statements_list]);

  const updateAnswer = (formType) => {
    setFormType(formType);
    setShowPopup(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onHide = () => {
    setShowPopup(false);
  };

  const back = () => {
    navigate(`${lastPage}`);
  };

  const generateAndShowLetter = async () => {
    setIsButtonDisabled(true);
    setIsSubmitting(true);
    try {
      const { data } = await getUser(token);

      const date1_1 = data.survey.date
        ? new Date(data.survey.date)
        : data.survey.date_last
        ? new Date(data.survey.date_last)
        : null;
      const date1_2 = data.survey.date1_2
        ? new Date(data.survey.date1_2)
        : null;
      const date1 =
        date1_1 && date1_2
          ? date1_1 < date1_2
            ? date1_1
            : date1_2
          : date1_1 || date1_2;

      // Custom function to add months reliably, handling month overflow
      function addMonthsReliable(date, months) {
        const d = new Date(date);
        const day = d.getDate();
        d.setMonth(d.getMonth() + months);

        // If the resulting day is smaller than the original day, we assume the overflow, and set it to the last valid day of the month
        if (d.getDate() < day) {
          d.setDate(0); // Set to the last day of the previous month
        }
        return d;
      }

      let time_limit = null;
      if (date1) {
        const dates = addMonthsReliable(date1, 3); // Use reliable month addition
        dates.setDate(dates.getDate() - 1); // Subtract 1 day from the 3-month date
        const mnth = `0${dates.getMonth() + 1}`.slice(-2);
        const day = `0${dates.getDate()}`.slice(-2);
        time_limit = `${dates.getFullYear()}-${mnth}-${day}`;
      } else {
        console.error("No valid date available");
      }

      const date2 = data.survey.date2 ? new Date(data.survey.date2) : null;
      const date3 = data.survey.date3 ? new Date(data.survey.date3) : null;
      let deadline2 = "";

      // Check if all three dates are present before proceeding with the calculation
      if (date1 && date2 && date3) {
        // First option: date1 + 3 calendar months minus 1 day + (day3 - day2)
        const date1PlusThreeMonths = addMonthsReliable(date1, 3);
        date1PlusThreeMonths.setDate(date1PlusThreeMonths.getDate() - 1); // Subtract 1 day

        // Calculate the difference in days between date3 and date2
        const dayDifference = Math.ceil(
          (date3 - date2) / (1000 * 60 * 60 * 24)
        );

        // Add this difference to the first deadline
        const firstDeadline = new Date(date1PlusThreeMonths);
        firstDeadline.setDate(firstDeadline.getDate() + dayDifference);

        // Second option: date3 + 1 calendar month minus 1 day
        const secondDeadline = addMonthsReliable(date3, 1);
        secondDeadline.setDate(secondDeadline.getDate() - 1);

        // Pick the later date between firstDeadline and secondDeadline
        const finalDeadline2 =
          firstDeadline > secondDeadline ? firstDeadline : secondDeadline;

        // Format deadline2 as YYYY-MM-DD
        const mnth2 = `0${finalDeadline2.getMonth() + 1}`.slice(-2);
        const day2 = `0${finalDeadline2.getDate()}`.slice(-2);
        deadline2 = [finalDeadline2.getFullYear(), mnth2, day2].join("-");
      }
      const empIssues = formatIssueText(
        surveyData.employment_issues,
        issues.employment
      );
      const othIssues =
        surveyData.other_issues &&
        formatIssueText(surveyData.other_issues, issues.other);
      const statementsList = statements
        .map((statement) => statement.attributes.Statement)
        .join(", ");
      const questions = JSON.parse(data.survey.generatedQuestions);
      const additionalQuestions = questions
        .map((q) => `Questions: ${q.title}\n\nAnswer: ${data.survey[q.name]}\n`)
        .join("\n");
      const manualQuestion = `Questions: If there is anything else you need us to know please write it here:\n\nAnswer: ${
        data.survey.extra_question ? data.survey.extra_question : "-"
      }`;
      const allQuestions = `${additionalQuestions}\n${manualQuestion}`;
      const optionalQuestion = `If there is anything else you need us to know please write it here:\n${
        data.survey.extra_question ? data.survey.extra_question : "-"
      }`;
      const encryptedUserEmail = encryptEmail(data.email);
      const baseUrl = window.location.origin;
      const formData = {
        userId: data.id,
        name: data.name,
        email: data.email,
        dealID: data.dealID,
        paragraph: data.survey.paragraph,
        date1: date1,
        time_limit: time_limit,
        time_limit2: deadline2,
        employmentIssues: empIssues,
        otherIssues: othIssues,
        statementsList: statementsList,
        additionalQuestions: allQuestions,
        optionalQuestion: optionalQuestion,
        empStatus: EMPLOYEMENT_STATUS[surveyData.employment_status],
        empLong: EMPLOYED_FOR[surveyData.how_long],
        wpLetterUrl: `${baseUrl}/client-review?customer=${encryptedUserEmail}`,
        ...data.survey,
      };
      if (!data.checkedWpLetter) {
        await generateCaseDetailLetter(formData);
      }
      await axios.post(`${config.ROTA_URL}update-case`, formData);
      navigate(`/client-review?customer=${encryptedUserEmail}`);
    } catch (error) {
      console.error("Error generating and showing the letter:", error);
      setIsButtonDisabled(false);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="update-letter">
        <div className="user-answers">
          <h2>Your information summary</h2>
          <p>
            <b>If the information is correct, please continue</b>
          </p>
          <p>If your information has changed, please update it below</p>
          <h4>What happened and when?</h4>
          <div className="answers-container">
            <div className="answers">
              {surveyData.case_summary &&
                renderSection(
                  "Description of your case",
                  surveyData.case_summary
                )}
            </div>
          </div>
          <h4>Important Dates</h4>
          <div className="answers-container">
            <div className="answers">
              {surveyData.date
                ? renderSection(
                    "Notice period",
                    `End date - ${surveyData.date
                      .split("-")
                      .reverse()
                      .join("-")}`
                  )
                : renderSection("Notice period", "No")}

              {surveyData.date_last &&
                renderSection(
                  "Notice period - End Date",
                  `End date - ${surveyData.date_last
                    .split("-")
                    .reverse()
                    .join("-")}`
                )}

              {surveyData.date1_2 &&
                renderSection(
                  "The latest incident",
                  surveyData.date1_2.split("-").reverse().join("-")
                )}

              {surveyData.date2
                ? renderSection(
                    "ACAS",
                    `Started on ${surveyData.date2
                      .split("-")
                      .reverse()
                      .join("-")}`
                  )
                : renderSection("ACAS", "Not started")}

              {surveyData.date3
                ? renderSection(
                    "ACAS certificate",
                    `Issued on ${surveyData.date3
                      .split("-")
                      .reverse()
                      .join("-")}`
                  )
                : renderSection("ACAS", "Not issued yet")}
            </div>
            <div
              className="action"
              onClick={() => updateAnswer("timelimits")}
              style={{ cursor: "pointer" }}
            >
              <img src={EditIcon} alt="icon" />
              Change
            </div>
          </div>
          <h4>Other information</h4>
          <div className="answers-container">
            <div className="answers">
              {surveyData.employment_issues &&
                renderSection(
                  "Employment issues",
                  formatIssueText(
                    surveyData.employment_issues,
                    issues.employment
                  )
                )}

              {surveyData.other_issues &&
                renderSection(
                  "Discrimination issues",
                  formatIssueText(surveyData.other_issues, issues.other)
                )}
              {statements.length > 0 &&
                renderSection(
                  "Statements",
                  statements
                    .map((statement) => statement.attributes.Statement)
                    .join(", ")
                )}
              {surveyData.employment_status &&
                renderSection(
                  "Are you still employed",
                  EMPLOYEMENT_STATUS[surveyData.employment_status]
                )}

              {surveyData.how_long &&
                renderSection(
                  "How long have you been in your job?",
                  EMPLOYED_FOR[surveyData.how_long]
                )}
              {surveyData.total_salary &&
                renderSection("Total annual salary", surveyData.total_salary)}

              {surveyData.offer_amount &&
                renderSection("Offer received", surveyData.offer_amount)}

              {surveyData.age && renderSection("Age", surveyData.age)}

              {surveyData.gender &&
                renderSection("Gender", capitalizeFLetter(surveyData.gender))}

              {surveyData.years_employed &&
                renderSection(
                  "How many years at this job?",
                  surveyData.years_employed
                )}

              {surveyData.start_date &&
                renderSection("New job start date", surveyData.start_date)}

              {surveyData.company_name &&
                renderSection("Employer company name", surveyData.company_name)}

              {surveyData.contact_name &&
                renderSection(
                  "Name of contact at employer",
                  surveyData.contact_name
                )}

              {surveyData.contact_email &&
                renderSection(
                  "Email of contact of employer",
                  surveyData.contact_email
                )}
            </div>
            {/* <div
              className="action"
              onClick={() => updateAnswer("questions")}
              style={{ cursor: "pointer" }}
            >
              <img src={EditIcon} alt="icon" />
              Change
            </div> */}
          </div>
          <h4>Questions and answers</h4>
          <div className="answers-container">
            <div
              className="answers"
              dangerouslySetInnerHTML={createMarkup(questionAnswers)}
            />
          </div>
        </div>
        <div className="action-buttons">
          <div>
            <div
              className="button back"
              onClick={back}
              style={{ cursor: "pointer" }}
            >
              Back
            </div>
            <div
              className="button continue"
              onClick={!isButtonDisabled ? generateAndShowLetter : null}
              style={{ cursor: isButtonDisabled ? "not-allowed" : "pointer" }}
              disabled={isButtonDisabled}
            >
              Continue
            </div>
          </div>
        </div>
      </div>
      {showPopup && <UpdateAtjData onHide={onHide} formType={formType} />}
      {isSubmitting && (
        <div className="submit-loader">
          <div>
            <img src={Cycle} width="auto" height="auto" alt="cycle" />
            <p>Loading letter, please wait...</p>
          </div>
        </div>
      )}
    </>
  );
}

export default PreviewLetter;
