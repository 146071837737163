import React from "react";

function Submitted() {
  return (
    <>
      <div className="ccl-completed container">
        <h2>Thank you!</h2>
        <p>Request have been submitted successfully.</p>
      </div>
    </>
  );
}

export default Submitted;
